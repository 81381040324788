import React from "react";
import styled from "styled-components";
import moment from "moment";

// const getSignalValue = (row) => {
//   if (!row.signal) {
//     return null;
//   }
//   if (row.signal.includes("BUY")) {
//     return (
//       <SignalContainer mode="buy">
//         <SignalText mode="buy"> {row.signal} </SignalText>
//       </SignalContainer>
//     );
//   } else if (row.signal.includes("SELL")) {
//     return (
//       <SignalContainer mode="sell">
//         <SignalText mode="sell"> {row.signal} </SignalText>
//       </SignalContainer>
//     );
//   } else if (row.signal.includes("BREAKEVEN")) {
//     return (
//       <SignalContainer>
//         <SignalText>{row.signal} </SignalText>
//       </SignalContainer>
//     );
//   }
// };

const TableRow = ({
  company: { trading_symbol },
  signalGenerationTime,
  signalGenerated,
  sl,
  target,
  value,
  went,
  profitOrLoss,
}) => {
  return (
    <Container key={trading_symbol}>
      <Item>{trading_symbol}</Item>
      <Item>{moment(signalGenerationTime).format("hh : mm : ss A")}</Item>
      <Item>
        {signalGenerated.includes("BUY_")
          ? "BUY"
          : signalGenerated.includes("SELL_")
          ? "SELL"
          : "BREAKEVEN"}
      </Item>
      <Item>{value}</Item>
      <Item>{sl && sl.toFixed(2)}</Item>
      <Item>{target && target.toFixed(2)}</Item>
      <Item>{went && went.toFixed(2)}</Item>
      <Item>{profitOrLoss && profitOrLoss.toFixed(2)}</Item>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 20rem;
  grid-gap: 1rem;
  padding: 0 3rem;
  border-bottom: 0.2px solid #3b82c5e0;
`;

const Item = styled.p`
  padding: 1rem 0;
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

export default TableRow;
