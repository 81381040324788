import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { SERVER_URI } from "./../../../../constants";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/utility/Loading";
import { AlertContext } from "../../../../context/AlertContext";

const PushNotification = () => {
  const [form, setForm] = useState({
    title: "",
    body: "",
  });
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (form.title.length < 3) {
      return addAlert({ message: "Provide Valid Title" });
    }
    if (form.body.length < 3) {
      return addAlert({ message: "Provide Valid Body" });
    }

    try {
      setLoading(true);
      await axios.post(`${SERVER_URI}/push-notification/send`, form, {
        headers: { admintoken: auth.token },
      });
      setForm({
        title: "",
        body: "",
      });
      return addAlert({ message: "Message Successfully Sent" });
    } catch (err) {
      return addAlert({ message: "Error Occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Send Push Notification</Title>
      <FormGroup>
        <Label>Title</Label>
        <Input
          placeholder="Title"
          value={form.title}
          onChange={(e) => {
            setForm({ ...form, title: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Body</Label>
        <Input
          placeholder="Body"
          value={form.body}
          onChange={(e) => {
            setForm({ ...form, body: e.target.value });
          }}
        />
      </FormGroup>

      {loading ? (
        <Loading />
      ) : (
        <SubmitButton
          value="Send Notification"
          type="submit"
          onClick={handleSubmit}
        />
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;
export default PushNotification;
