import React, { useContext } from "react";
import { Router } from "@reach/router";
import Navigation from "./components/Navigation";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import PushNotification from "./pages/PushNotification";
import Companies from "./pages/Companies";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import { AuthContext } from "./context/AuthContext";

const RootDOM = () => {
  const { auth } = useContext(AuthContext);
  if (!auth) {
    return <Login />;
  }
  return (
    <div>
      <Navigation />
      <Router>
        <Users path="users/*" />
        <Home path="/" />
        <Reports path="reports/*" />
        <Companies path="companies/*" />
        <PushNotification path="push-notification/*" />
        <Settings path="/settings/*" />
      </Router>
    </div>
  );
};

export default RootDOM;
