import React from "react";
import styled from "styled-components";
import { Link, Router } from "@reach/router";
import AllUsers from "./pages/AllUsers";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";

const Users = () => {
  return (
    <Container>
      <Wrapper>
        <RouterTitle>Users</RouterTitle>
        <Header>
          <StyledLink
            to=""
            getProps={({ isCurrent }) => {
              return {
                style: {
                  color: isCurrent ? "#090e1b" : "#889db3",
                  borderBottomColor: isCurrent ? "#1bb6c1" : "transparent",
                },
              };
            }}
          >
            Users
          </StyledLink>

          <StyledLink
            to="add"
            getProps={({ isCurrent }) => {
              return {
                style: {
                  color: isCurrent ? "#090e1b" : "#889db3",
                  borderBottomColor: isCurrent ? "#1bb6c1" : "transparent",
                },
              };
            }}
          >
            Add User
          </StyledLink>
        </Header>
        <ContentWrapper>
          <Router>
            <AllUsers path="/" />
            <AddUser path="add" />
            <EditUser path=":_id" />
          </Router>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 2rem 0;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 3rem;
`;

const RouterTitle = styled.h1`
  color: #090e1b;
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const Header = styled.ul`
  display: flex;
  list-style: none;
  border-bottom: 0.5px solid #e4ebf2;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  transition: all 0.2s;
`;

const ContentWrapper = styled.div``;

export default Users;
