import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import { AuthContext } from "../../../../context/AuthContext";
import { SERVER_URI } from "../../../../constants";

import { Link } from "@reach/router";

const ITEMS_PER_PAGE = 50;

const AllCompanies = () => {
  const { auth } = useContext(AuthContext);
  const [companies, setCompanies] = useState(null);
  const [totalCompanies, setTotalCompanies] = useState(null);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    axios
      .get(
        `${SERVER_URI}/companies?name=${searchQuery}&limit=${ITEMS_PER_PAGE}&skip=${
          (page - 1) * ITEMS_PER_PAGE
        }`,
        { headers: { admintoken: auth.token } }
      )
      .then(({ data: { companies, totalCompanies } }) => {
        setCompanies(companies);
        setTotalCompanies(totalCompanies);
      });
  }, [page, searchQuery]);

  return (
    <Container>
      <TableWrapper>
        <TableContent>
          <FormGroup>
            <Input
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </FormGroup>
          <TableHeader>
            <TableHeaderItem>Name</TableHeaderItem>
            <TableHeaderItem>Trading Symbol</TableHeaderItem>
            <TableHeaderItem>Exchange</TableHeaderItem>
          </TableHeader>
          <TableBody>
            {companies &&
              companies.map((company) => (
                <TableRowContainer key={company._id}>
                  <TableRowItem>{company.name}</TableRowItem>
                  <TableRowItem>{company.trading_symbol}</TableRowItem>
                  <TableRowItem>{company.exchange}</TableRowItem>
                  <StyledLink to={company._id}>Edit</StyledLink>
                </TableRowContainer>
              ))}
          </TableBody>
        </TableContent>
      </TableWrapper>
      {totalCompanies && (
        <PagesContainer>
          {page !== 1 && (
            <>
              <Page onClick={() => setPage(page - 1)}>Previous</Page>
              <Page onClick={() => setPage(page - 1)}>{page - 1}</Page>
            </>
          )}
          <Page active={true}>{page}</Page>
          {page !== Math.ceil(totalCompanies / ITEMS_PER_PAGE) && (
            <>
              <Page onClick={() => setPage(page + 1)}>{page + 1}</Page>
              <Page
                onClick={() => {
                  setPage(page + 1);
                }}>
                Next
              </Page>
            </>
          )}
        </PagesContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 120rem;
  background-color: white;
  margin: 2rem auto;
  margin-top: 0;
  padding: 3rem 0;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 3rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 80rem;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 20rem 10rem 4rem;

  grid-gap: 1rem;
  padding: 0.2rem 0rem;
  background-color: #f0f5f8;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: #6c7887;
`;

const TableBody = styled.div``;

const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20rem 10rem 4rem;
  grid-gap: 1rem;
  border-bottom: 0.2px solid #3b82c5e0;
`;

const TableRowItem = styled.p`
  padding: 1rem 0;
  font-size: 1.5rem;
  line-height: 2.1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

const PagesContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  padding: 10px;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  /* text-decoration: none; */
  font-style: italic;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

export default AllCompanies;
