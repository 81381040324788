import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Checkbox from "../../../../components/utility/Checkbox";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import validator from "validator";
import { AlertContext } from "../../../../context/AlertContext";

const AddUser = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    city: "",
    phoneNumber: "",
    amountPaid: "",
    password: "",
    isPremium: false,
    hasReportAccess: false,
    hasReportDownloadAccess: false,
    hasReportDownloadAndEditAccess: false,
    flag1: false,
    flag2: false,
    flag3: false,
    flag4: false,
    flag5: false,
  });
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(form.email)) {
      return addAlert({ message: "Enter Valid Email" });
    }
    if (String(form.phoneNumber).length < 10) {
      return addAlert({ message: "Enter Valid Phone Number" });
    }

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${SERVER_URI}/users`, form, {
        headers: { admintoken: auth.token },
      });
      setForm({
        name: "",
        email: "",
        city: "",
        phoneNumber: "",
        amountPaid: "",
        password: "",
        isPremium: false,
        hasReportAccess: false,
        hasReportDownloadAccess: false,
        hasReportDownloadAndEditAccess: false,
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      });
      window.scrollTo(0, 0);
      return addAlert({ message: "User successfully Created" });
    } catch (err) {
      if (
        err?.response?.data &&
        Object.values(err?.response?.data).length > 0
      ) {
        let msg = "| ";
        Object.values(err.response.data).forEach((val) => {
          msg += `${val} | `;
        });
        addAlert({
          message: msg,
        });
      } else {
        addAlert({
          message: "Provide Valid Credentials",
        });
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Add User</Title>
      <FormGroup>
        <Label>Name</Label>
        <Input
          placeholder="Name"
          value={form.name}
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <Input
          placeholder="Email"
          value={form.email}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>City</Label>
        <Input
          placeholder="City"
          value={form.city}
          onChange={(e) => {
            setForm({ ...form, city: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Phone Number</Label>
        <Input
          type="number"
          placeholder="Phone Number"
          value={form.phoneNumber}
          onChange={(e) => {
            setForm({ ...form, phoneNumber: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Amount Paid</Label>
        <Input
          type="number"
          placeholder="Amount Paid"
          value={form.amountPaid}
          onChange={(e) => {
            setForm({ ...form, amountPaid: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input
          type="password"
          placeholder="Password"
          value={form.password}
          onChange={(e) => {
            setForm({ ...form, password: e.target.value });
          }}
        />
      </FormGroup>
      <Checkbox
        checked={form.isPremium}
        onChange={(checked) => {
          setForm({ ...form, isPremium: checked });
        }}
        label="Is Premium ?"
      />

      <Checkbox
        checked={form.hasReportAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportAccess: checked });
        }}
        label="Has Report Access ?"
      />
      <Checkbox
        checked={form.hasReportDownloadAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportDownloadAccess: checked });
        }}
        label="Has Report Download Access ?"
      />
      <Checkbox
        checked={form.hasReportDownloadAndEditAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportDownloadAndEditAccess: checked });
        }}
        label="Has Report Download And Edit Access ?"
      />
      <Checkbox
        checked={form.flag1}
        onChange={(checked) => {
          setForm({ ...form, flag1: checked });
        }}
        label="Flag 1"
      />
      <Checkbox
        checked={form.flag2}
        onChange={(checked) => {
          setForm({ ...form, flag2: checked });
        }}
        label="Flag 2"
      />
      <Checkbox
        checked={form.flag3}
        onChange={(checked) => {
          setForm({ ...form, flag3: checked });
        }}
        label="Flag 3"
      />
      <Checkbox
        checked={form.flag4}
        onChange={(checked) => {
          setForm({ ...form, flag4: checked });
        }}
        label="Flag 4"
      />
      <Checkbox
        checked={form.flag5}
        onChange={(checked) => {
          setForm({ ...form, flag5: checked });
        }}
        label="Flag 5"
      />
      {loading ? (
        <Loading />
      ) : (
        <SubmitButton value="Add User" type="submit" onClick={handleSubmit} />
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default AddUser;
