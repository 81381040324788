import React, { createContext, useState, useCallback } from "react";
import { navigate } from "@reach/router";

export const AuthContext = createContext();

let authFromLocalStorage = localStorage.getItem("auth");
if (authFromLocalStorage) {
  authFromLocalStorage = JSON.parse(authFromLocalStorage);
}

export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(authFromLocalStorage);

  const login = useCallback((data) => {
    setAuth(data);
    localStorage.setItem("auth", JSON.stringify(data));
  }, []);

  const logout = useCallback(() => {
    navigate("/");
    setAuth(null);
    localStorage.removeItem("auth");
  }, []);

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
