import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";

const Constants = (props) => {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [constants, setConstants] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get(`${SERVER_URI}/general/constants`, {
        headers: { admintoken: auth.token },
      })
      .then(({ data }) => {
        setConstants(data);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
  }, []);

  if (error) {
    return (
      <Container>
        <Title>Error Occurred</Title>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Constants</Title>
      <KeyValue>
        <Key>Buy Constant</Key>
        <Value>{constants.BUY_CONSTANT}</Value>
      </KeyValue>
      <KeyValue>
        <Key>Sell Constant</Key>
        <Value>{constants.SELL_CONSTANT}</Value>
      </KeyValue>

      <KeyValue>
        <Key>Stop Loss For Buy</Key>
        <Value>{constants.STOP_LOSS_FOR_BUY}</Value>
      </KeyValue>

      <KeyValue>
        <Key>Target For Buy</Key>
        <Value>{constants.TARGET_FOR_BUY}</Value>
      </KeyValue>
      <KeyValue>
        <Key>Stop Loss For Sell</Key>
        <Value>{constants.STOP_LOSS_FOR_SELL}</Value>
      </KeyValue>
      <KeyValue>
        <Key>Target For Sell</Key>
        <Value>{constants.TARGET_FOR_SELL}</Value>
      </KeyValue>
      <KeyValue>
        <Key>Mailer Email</Key>
        <Value>{constants.MAILER_EMAIL}</Value>
        <KeyValue>
          <Key>Mailer Password</Key>
          <Value>{constants.MAILER_PASSWORD}</Value>
        </KeyValue>
      </KeyValue>
    </Container>
  );
};

const Container = styled.div`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const KeyValue = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Key = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Value = styled.p`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  /* border-radius: 2px; */
  outline: none;
`;

export default Constants;
