import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import TableRow from "./TableRow";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";

const ITEMS_PER_PAGE = 100;

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

function ReportTable({ auth, date }) {
  const [page, setPage] = useState(1);
  const [customSort, setCustomSort] = useState(null);
  const [totalReports, setTotalReports] = useState(null);
  const [data, setData] = useReducer((state, action) => {
    switch (action.type) {
      case "UPDATE WHOLE":
        return action.payload;
      case "CUSTOM SORT":
        if (!state) {
          return state;
        }
        let currentData = [...state];
        const mode = action.payload.mode;
        const identifier = action.payload.for;
        currentData.sort((a, b) => {
          if (mode === "ascending") {
            if (
              Object.byString(a, identifier) > Object.byString(b, identifier)
            ) {
              return -1;
            } else return 0;
          } else {
            if (
              Object.byString(a, identifier) < Object.byString(b, identifier)
            ) {
              return -1;
            } else return 0;
          }
        });
        return currentData;
      default:
        return state;
    }
  }, null);

  const fetchReport = async () => {
    try {
      const {
        data: { reports, totalReports: total },
      } = await axios.get(
        `${SERVER_URI}/reports?date=${date.toString()}&skip=${
          (page - 1) * ITEMS_PER_PAGE
        }&limit=${ITEMS_PER_PAGE}`,
        { headers: { admintoken: auth.token } }
      );

      if (total > ITEMS_PER_PAGE && total !== totalReports) {
        setTotalReports(total);
      }
      setData({ type: "UPDATE WHOLE", payload: reports });
    } catch (err) {}
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchReport();
    }
    setTotalReports(null);
  }, [date]);

  useEffect(() => {
    fetchReport();
  }, [page]);

  useEffect(() => {
    if (customSort) {
      setData({
        type: "CUSTOM SORT",
        payload: customSort,
      });
      return;
    }
  }, [customSort]);

  return (
    <Container>
      <TableWrapper>
        <TableContent>
          <TableHeader>
            <HeaderItem
              for="company.trading_symbol"
              customSort={customSort}
              name="Script Name"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="signalGenerationTime"
              customSort={customSort}
              name="Signal Generation Time"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="signalGenerated"
              customSort={customSort}
              name="Signal Generated"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="value"
              customSort={customSort}
              name="Value"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="sl"
              customSort={customSort}
              name="SL"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="target"
              customSort={customSort}
              name="Target"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="went"
              customSort={customSort}
              name="Went Upto"
              setCustomSort={setCustomSort}
            />
            <HeaderItem
              for="profitOrLoss"
              customSort={customSort}
              name="Profit Or Loss"
              setCustomSort={setCustomSort}
            />
          </TableHeader>
          <TableBody>
            {data ? (
              data.length > 0 ? (
                data.map((eachData) => (
                  <TableRow key={eachData._id} {...eachData} />
                ))
              ) : (
                <NoReports>No Reports For This Day</NoReports>
              )
            ) : null}
          </TableBody>
        </TableContent>
      </TableWrapper>
      {totalReports && (
        <PagesContainer>
          {page !== 1 && (
            <>
              <Page onClick={() => setPage(page - 1)}>Previous</Page>
              <Page onClick={() => setPage(page - 1)}>{page - 1}</Page>
            </>
          )}

          {page >= 1 && <Page active={true}>{page}</Page>}

          {page !== Math.ceil(totalReports / ITEMS_PER_PAGE) && (
            <>
              <Page onClick={() => setPage(page + 1)}>{page + 1}</Page>
              <Page
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Next
              </Page>
            </>
          )}
        </PagesContainer>
      )}
    </Container>
  );
}

const HeaderItem = ({ customSort, setCustomSort, for: identifier, name }) => {
  return (
    <TableHeaderItem
      onClick={() => {
        if (!customSort || customSort.for !== identifier) {
          return setCustomSort({
            for: identifier,
            mode: "ascending",
          });
        }
        if (
          customSort &&
          customSort.for === identifier &&
          customSort.mode === "ascending"
        ) {
          return setCustomSort({
            for: identifier,
            mode: "descending",
          });
        }
        if (
          customSort &&
          customSort.for === identifier &&
          customSort.mode === "descending"
        ) {
          return setCustomSort(null);
        }
      }}
    >
      {name}{" "}
      {customSort && customSort.for === identifier
        ? customSort.mode === "descending"
          ? ChevronDown
          : ChevronUp
        : null}
    </TableHeaderItem>
  );
};

const Container = styled.div`
  max-width: 100%;
  background-color: white;
  margin: 0 auto;
`;

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 3rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 114rem;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const NoReports = styled.h2`
  margin: 3rem 0;
  color: #05a0ab;
  font-size: 1.8rem;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 20rem;

  grid-gap: 1rem;
  padding: 0.2rem 3rem;
  background-color: #f0f5f8;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: #6c7887;
`;

const TableBody = styled.div``;

const PagesContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const ChevronDown = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
  >
    <path
      d="M10.293 0.292969L6 4.58597L1.707 0.292969L0.292999 1.70697L6 7.41397L11.707 1.70697L10.293 0.292969Z"
      fill="#6c7887"
    />
  </svg>
);

const ChevronUp = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
  >
    <path
      d="M1.707 7.41406L6 3.12106L10.293 7.41406L11.707 6.00006L6 0.293064L0.293 6.00006L1.707 7.41406Z"
      fill="#6c7887"
    />
  </svg>
);

export default ReportTable;
