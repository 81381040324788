import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, Router } from "@reach/router";
import Report from "./pages/Report";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { SERVER_URI } from "../../constants";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

let initialDate = new Date();

if (!isWeekday(initialDate)) {
  initialDate = moment(initialDate).subtract("1", "day").toDate();
}

if (!isWeekday(initialDate)) {
  initialDate = moment(initialDate).subtract("1", "day").toDate();
}

const Reports = () => {
  const [date, setDate] = useState(initialDate);
  const { auth } = useContext(AuthContext);

  const handleCSVDownload = () => {
    axios
      .get(`${SERVER_URI}/reports/csv?date=${date.toString()}`, {
        headers: { admintoken: auth.token },
      })
      .then((res) => {
        const type = res.headers["content-type"];
        const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "report.csv";
        link.click();
      });
  };

  const handlePDFDownload = () => {
    window.open(
      `${SERVER_URI}/reports/pdf?date=${date.toString()}&admintoken=${
        auth.token
      }`
    );
  };

  return (
    <Container>
      <Wrapper>
        <RouterTitle>Report</RouterTitle>
        <HeaderWrapper>
          <Header>
            <StyledLink
              to=""
              getProps={({ isCurrent }) => {
                return {
                  style: {
                    color: isCurrent ? "#090e1b" : "#889db3",
                    borderBottomColor: isCurrent ? "#1bb6c1" : "transparent",
                  },
                };
              }}
            >
              Report
            </StyledLink>
            <DownloadWrapper>
              <DownloadContainer onClick={handleCSVDownload}>
                <p>Download CSV</p>
              </DownloadContainer>
              <DownloadContainer onClick={handlePDFDownload}>
                <p>Download PDF</p>
              </DownloadContainer>
            </DownloadWrapper>
            <div>
              <ReactDatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                dateFormat="MMMM d, yyyy"
                showPopperArrow={false}
                className="datepicker"
                calendarClassName="datepicker-calendar"
                maxDate={new Date()}
                filterDate={isWeekday}
              />
            </div>
          </Header>
        </HeaderWrapper>
        <ContentWrapper>
          <Router>
            <Report auth={auth} date={date} path="/" />
          </Router>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div``;

const Wrapper = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 3rem;
`;

const RouterTitle = styled.h1`
  color: #090e1b;
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`;

const Header = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  list-style: none;
  border-bottom: 0.5px solid #e4ebf2;

  @media (max-width: 563px) {
    justify-content: center;
    & > * {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  transition: all 0.2s;
`;

const ContentWrapper = styled.div``;

const DownloadWrapper = styled.div`
  display: inline-flex;
  margin: 0 auto;
  align-self: center;
`;

const DownloadContainer = styled.div`
  cursor: pointer;
  p {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: #090e1b;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Reports;
