import React from "react";
import styled from "styled-components";

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <Styled onClick={() => onChange(!checked)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        style={{ display: "none" }}
      />
      <label>{label}</label>
    </Styled>
  );
};
const Styled = styled.div`
  display: inline-block;
  margin: 1rem 0;
  > input {
    opacity: 0;
  }
  > input + label {
    font-size: 1.4rem;
    font-weight: 500;
    color: #414447;
    display: block;
    position: relative;
    padding-left: 25px;
    text-align: left;
    cursor: pointer;
    font-weight: 500;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 1px;
      width: 17px;
      height: 17px; /* dim. de la case */
      border: 1px solid rgba(0, 0, 0, 0.5);
      background: #f8f8f8;
      border-radius: 3px; /* angles arrondis */
    }
    &:after {
      content: "✔";
      position: absolute;
      top: -1px;
      left: 2px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s; /* on prévoit une animation */
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0; /* coche invisible */
      transform: scale(0); /* mise à l'échelle à 0 */
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.5);
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1; /* coche opaque */
      transform: scale(1); /* mise à l'échelle 1:1 */
    }
  }
`;
export default Checkbox;
