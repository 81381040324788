import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Checkbox from "../../../../components/utility/Checkbox";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import validator from "validator";
import { AlertContext } from "../../../../context/AlertContext";
import { navigate } from "@reach/router";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditUser = (props) => {
  const [form, setForm] = useState(null);
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    axios
      .get(`${SERVER_URI}/users/${props._id}`, {
        headers: { admintoken: auth.token },
      })
      .then(({ data: { user } }) => {
        setForm({ ...user, endDate: new Date(user.endDate) });
      })
      .catch((e) => {
        setError(true);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(form.email)) {
      return addAlert({ message: "Enter Valid Email" });
    }
    if (String(form.phoneNumber).length < 10) {
      return addAlert({ message: "Enter Valid Phone Number" });
    }

    if (loading) {
      return;
    }
    let dataToSend = { ...form };

    delete dataToSend.webSession;
    delete dataToSend.startDate;

    try {
      setLoading(true);
      await axios.patch(`${SERVER_URI}/users/${props._id}`, dataToSend, {
        headers: { admintoken: auth.token },
      });
      navigate("/users");
      return addAlert({ message: "User successfully Updated" });
    } catch (err) {
      return addAlert({ message: "Please provide valid details" });
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await axios.delete(`${SERVER_URI}/users/${props._id}`, {
        headers: { admintoken: auth.token },
      });
      navigate("/users");
      return addAlert({ message: "User Removed" });
    } catch (err) {
      return addAlert({ message: "Action Failed !" });
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <Form>
        <Title>Error Occurred</Title>
      </Form>
    );
  }

  if (!form) {
    return (
      <Form>
        <Loading />
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Update User</Title>
      <FormGroup>
        <Label>Name</Label>
        <Input
          placeholder="Name"
          value={form.name}
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <Input
          placeholder="Email"
          value={form.email}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>City</Label>
        <Input
          placeholder="City"
          value={form.city}
          onChange={(e) => {
            setForm({ ...form, city: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Phone Number</Label>
        <Input
          type="number"
          placeholder="Phone Number"
          value={form.phoneNumber}
          onChange={(e) => {
            setForm({ ...form, phoneNumber: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Amount Paid</Label>
        <Input
          type="number"
          placeholder="Amount Paid"
          value={form.amountPaid}
          onChange={(e) => {
            setForm({ ...form, amountPaid: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>End Date</Label>
        <ReactDatePicker
          selected={form.endDate}
          onChange={(date) => setForm({ ...form, endDate: date })}
          dateFormat="MMMM d, yyyy"
          showPopperArrow={false}
          className="datepicker"
          calendarClassName="datepicker-calendar"
        />
      </FormGroup>
      <Checkbox
        checked={form.isPremium}
        onChange={(checked) => {
          setForm({ ...form, isPremium: checked });
        }}
        label="Is Premium ?"
      />

      <Checkbox
        checked={form.hasReportAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportAccess: checked });
        }}
        label="Has Report Access ?"
      />
      <Checkbox
        checked={form.hasReportDownloadAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportDownloadAccess: checked });
        }}
        label="Has Report Download Access ?"
      />
      <Checkbox
        checked={form.hasReportDownloadAndEditAccess}
        onChange={(checked) => {
          setForm({ ...form, hasReportDownloadAndEditAccess: checked });
        }}
        label="Has Report Download And Edit Access ?"
      />
      <Checkbox
        checked={form.flag1}
        onChange={(checked) => {
          setForm({ ...form, flag1: checked });
        }}
        label="Flag 1"
      />
      <Checkbox
        checked={form.flag2}
        onChange={(checked) => {
          setForm({ ...form, flag2: checked });
        }}
        label="Flag 2"
      />
      <Checkbox
        checked={form.flag3}
        onChange={(checked) => {
          setForm({ ...form, flag3: checked });
        }}
        label="Flag 3"
      />
      <Checkbox
        checked={form.flag4}
        onChange={(checked) => {
          setForm({ ...form, flag4: checked });
        }}
        label="Flag 4"
      />
      <Checkbox
        checked={form.flag5}
        onChange={(checked) => {
          setForm({ ...form, flag5: checked });
        }}
        label="Flag 5"
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <SubmitButton
            value="Update User"
            type="submit"
            onClick={handleSubmit}
          />
          <DeleteButton
            value="Delete User"
            type="submit"
            onClick={handleRemove}
          />
        </>
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const DeleteButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #e02900;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default EditUser;
