import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { SERVER_URI } from "./../../../../constants";
import { AuthContext } from "../../../../context/AuthContext";

import Loading from "../../../../components/utility/Loading";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const HomePage = () => {
  const { auth } = useContext(AuthContext);
  const [allSignalsChartData, setAllSignalsChartData] = useState(null);
  const [eachSignalChartData, setEachSignalChartData] = useState(null);
  console.log("Hi");
  useEffect(() => {
    axios
      .get(`${SERVER_URI}/reports/chartData`, {
        headers: { admintoken: auth.token },
      })
      .then(({ data: { eachSignalReportData, allSignalsInEachDay } }) => {
        let allSignalData = allSignalsInEachDay
          .map((data) => ({
            Date: data.dateDMY,
            Count: data.count,
          }))
          .reverse();

        setAllSignalsChartData(allSignalData);

        let eachSignalData = [];
        let currentDate = null;

        eachSignalReportData.forEach((eachData) => {
          if (eachData.dateDMY === currentDate) {
            eachSignalData[eachSignalData.length - 1][
              eachData.signalGenerated
            ] = eachData.count;
          } else {
            currentDate = eachData.dateDMY;
            eachSignalData.push({
              [eachData.signalGenerated]: eachData.count,
              Date: eachData.dateDMY,
            });
          }
        });

        eachSignalData = eachSignalData
          .map((each) => {
            const Breakeven = each.BREAKEVEN || 0;
            let Buy = 0;
            let Sell = 0;

            if (each.BUY_ABOVE_DAY_HIGH) {
              Buy += each.BUY_ABOVE_DAY_HIGH;
            }
            if (each.BUY_ABOVE_TEN_DAY_HIGH) {
              Buy += each.BUY_ABOVE_TEN_DAY_HIGH;
            }

            if (each.SELL_BELOW_DAY_LOW) {
              Sell += each.SELL_BELOW_DAY_LOW;
            }
            if (each.SELL_BELOW_TEN_DAY_LOW) {
              Sell += each.SELL_BELOW_TEN_DAY_LOW;
            }

            return { Breakeven, Buy, Sell, Date: each.Date };
          })
          .reverse();

        setEachSignalChartData(eachSignalData);
      });
  }, []);

  if (!allSignalsChartData) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <Container>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={allSignalsChartData}
          margin={{
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar barSize={17} dataKey="Count" fill="#1bb6c1" />
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={eachSignalChartData}
          margin={{
            top: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Buy" fill="#04A780" barSize={10} />
          <Bar dataKey="Sell" fill="#AD0000" barSize={10} />
          <Bar dataKey="Breakeven" fill="#B67600" barSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const LoadingWrapper = styled.div`
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2rem;
  margin: 5rem 0 0 0;
  @media (max-width: 866px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export default HomePage;
