import React from "react";

import { AuthContextProvider } from "./context/AuthContext";
import RootDOM from "./RootDOM";
import { AlertContextProvider } from "./context/AlertContext";

function App() {
  return (
    <AlertContextProvider>
      <AuthContextProvider>
        <RootDOM />
      </AuthContextProvider>
    </AlertContextProvider>
  );
}

export default App;
