import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import Checkbox from "../../../../components/utility/Checkbox";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import { AlertContext } from "../../../../context/AlertContext";

const EditCompany = (props) => {
  const [form, setForm] = useState(null);
  const { auth } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    axios
      .get(`${SERVER_URI}/companies/${props._id}`, {
        headers: { admintoken: auth.token },
      })
      .then(({ data: { company } }) => {
        setForm(company);
      })
      .catch((e) => {
        setError(true);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    let dataToSend = { ...form };

    try {
      setLoading(true);
      await axios.patch(`${SERVER_URI}/companies/${props._id}`, dataToSend, {
        headers: { admintoken: auth.token },
      });
      addAlert({ message: "Successfully Updated" });
      props.navigate("/companies");
    } catch (err) {
      addAlert({ message: "Unknown Error Occurred" });
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <Form>
        <Title>Error Occurred</Title>
      </Form>
    );
  }

  if (!form) {
    return (
      <Form>
        <Loading />
      </Form>
    );
  }
  console.log(form);
  return (
    <Form onSubmit={handleSubmit}>
      <Title>Update Company Details</Title>
      <FormGroup>
        <Label>Name</Label>
        <Input
          placeholder="Name"
          value={form.name}
          onChange={(e) => {
            setForm({ ...form, name: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Trading Symbol</Label>
        <Input
          placeholder="Trading Symbol"
          value={form.trading_symbol}
          onChange={(e) => {
            setForm({ ...form, trading_symbol: e.target.value });
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Exchange</Label>
        <Input
          placeholder="Exchange"
          value={form.exchange}
          onChange={(e) => {
            setForm({ ...form, exchange: e.target.value });
          }}
        />
      </FormGroup>

      <FormGroup>
        <Checkbox
          label="Is Fno ?"
          checked={form.isFno}
          onChange={(checked) => setForm({ ...form, isFno: checked })}
        />
      </FormGroup>

      <FormGroup>
        <Label>Lot Size</Label>
        <Input
          placeholder="Lot Size"
          value={form.lotSize}
          onChange={(e) => {
            setForm({ ...form, lotSize: e.target.value });
          }}
        />
      </FormGroup>

      {loading ? (
        <Loading />
      ) : (
        <SubmitButton
          value="Update Company Details"
          type="submit"
          onClick={handleSubmit}
        />
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default EditCompany;
